<template>
    <div class="footer-page">
        <div class="top-footer-wrap">
            <div class="top-footer wid1200">
                <div class="left-top-footer com-footer">
                    <p>北京市西城区北三环中路甲29号院3号楼"华龙大厦"A/B座13层、15层</p>
                    <p>邮编：100029　邮箱：database@ssap.cn</p>
                    <p>编辑服务联系电话：010-59367076</p>
                    <p>销售服务联系电话：400-0086-695</p>
                    <p>客服qq：2475522410</p>
                </div>
                <div class="right-top-footer com-footer">
                    <div>
                        <img src="../assets/img/code1.png" alt="">
                        <p>手机版</p>
                    </div>
                    <div>
                        <img src="../assets/img/code2.jpg" alt="">
                        <p>公众号</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bot-footer-wrap">
            版权所有 社会科学文献出版社　<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #fff;">京ICP备06036494号-27</a>　<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=110102003507" target="_blank" style="color: #fff;margin-left: 8px;">京公网安备110102003507</a>　新出网证（京）字094号
        </div>
        
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {},
    created() {}
}
</script>

<style lang="scss" scoped>
.footer-page {
    width: 100%;
    background: #fff;
    .top-footer-wrap {
        margin-top: 31px;
        background: #bd1a2d;
        height: 207px;
        box-sizing: border-box;
        padding: 33px 0 0;
        .top-footer {
            display: flex;
            justify-content: space-between;
            .left-top-footer {
                width: 792px;
                box-sizing: border-box;
                padding: 20px 40px;

                p {
                    text-align: left;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fff;
                    line-height: 23px;
                }
            }
            .right-top-footer {
                width: 398px;
                height: 159px;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                padding: 13px 70px 0;
                div {
                    img {
                        width: 112px;
                        height: 113px;
                    }
                    p {
                        line-height: 21px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #fff;
                        text-align: center;
                    }
                }
            }
            .com-footer {
                background: #a91728;
            }
        }
    }
    .bot-footer-wrap {
        background: #0D1020;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        line-height: 60px;
    }
}
</style>
